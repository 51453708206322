<template>
  <div v-if="isSuccess && data">
    <ul class="tw-mb-8 tw-flex tw-flex-1 tw-flex-col tw-place-content-between">
      <li
        v-for="(step, index) of statuses"
        :key="`status-${index}`"
        class="tw-flex tw-h-full tw-gap-2"
      >
        <div class="tw-flex tw-w-8 tw-flex-col tw-items-center">
          <div class="tw-absolute tw-h-auto tw-w-6 tw-rounded-full tw-bg-gray-200" />
          <div
            class="tw-relative tw-flex tw-h-full !tw-w-6 tw-items-start tw-justify-center tw-text-xs"
            :class="[
              index <= statusIndex - 2 ? [darkGradientColor, 'tw-text-white'] : '',
              index == statusIndex - 1 ? ['tw-bg-gradient-to-b', linearGradient] : '',
              index > statusIndex - 2 && index !== statuses.length - 1 ? 'tw-bg-gray-200' : '',
              index === 0 ? 'tw-rounded-t-full' : '',
              index === statuses.length - 1 ? 'tw-rounded-b-full' : '',
            ]"
          >
            <div
              v-if="index === statusIndex"
              class="tw-absolute tw--top-2 tw-flex tw-h-[40px] tw-w-6 tw-items-start tw-justify-center"
              :class="[
                statusIndex === 0
                  ? ['tw-rounded-full', lightGradientColor]
                  : ['tw-rounded-b-full', lightGradientColor],
              ]"
            >
              <div
                class="tw-mt-2 tw-flex tw-h-[22px] tw-w-[22px] tw-items-center tw-justify-center tw-rounded-full tw-border-[3px]"
                :class="trackerDots"
              />
            </div>
            <div
              v-if="index === statuses.length - 1"
              class="tw-absolute tw--top-2 tw-flex tw-h-[40px] tw-w-6 tw-items-start tw-justify-center tw-rounded-b-full"
              :class="[statusIndex === statuses.length - 1 ? lightGradientColor : 'tw-bg-gray-200']"
            >
              <div
                class="tw-mt-2 tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-border-[3px]"
                :class="trackerDots"
              />
            </div>
            <div
              v-if="index < statusIndex"
              class="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full"
            >
              <CheckmarkCircle class="!tw-h-6 !tw-w-6 tw-text-white" />
            </div>
            <div
              v-else-if="index >= statusIndex"
              class="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-border-[3px]"
              :class="trackerDots"
            />
          </div>
        </div>
        <div class="tw-flex-1 tw-pb-8">
          <div
            class="tw-font-outfit tw-text-lg tw-font-semibold tw-text-gray-800 first-letter:tw-uppercase"
          >
            {{ step.name }}
          </div>
          <Disclosure v-slot="{ open: isOpen }" :default-open="index === statusIndex">
            <DisclosurePanel class="tw-text-gray-700">
              {{ step.description }}
            </DisclosurePanel>
            <DisclosureButton
              class="tw-no-underline hover:tw-underline"
              :class="primaryText"
              @click="() => trackClick(`View ${isOpen ? 'less' : 'more'}`)"
            >
              View {{ isOpen ? 'less' : 'more' }}
            </DisclosureButton>
          </Disclosure>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import CheckmarkCircle from '~icons/fluent/checkmark-circle-16-filled'

const { data, isSuccess } = useClaimStatusQuery()

const { getThemeToken } = useTheme()
const trackerDots = getThemeToken('tracker-dots')
const linearGradient = getThemeToken('linear-gradient')
const lightGradientColor = getThemeToken('light-linear-gradient')
const darkGradientColor = getThemeToken('dark-linear-gradient')
const primaryText = getThemeToken('primary-text')

const analytics = useAnalytics()

function trackClick(text: string) {
  analytics.trackElementClicked({
    elementType: 'button',
    text,
  })
}

const statuses = computed(() => data.value?.statuses ?? [])
const statusIndex = computed(() => data.value?.status?.index ?? 0)
</script>
