<template>
  <div v-if="isSuccess && data">
    <div v-if="$device.isDesktop || $device.isTablet">
      <div
        v-if="['other', 'canceled'].includes(data.status.name)"
        class="tw-rounded-2xl tw-bg-white tw-p-8 tw-drop-shadow lg:tw-mt-8"
      >
        <div class="tw-mb-6 tw-flex tw-items-center">
          <img
            :src="logoUrl"
            :alt="branding.name"
            class="tw-max-w-52 tw-mr-3 tw-h-auto tw-max-h-9 tw-w-auto"
          />
        </div>
        <div class="tw-flex tw-gap-2">
          <div>
            <Heading
              :level="5"
              class="tw-font-outfit !tw-text-2xl tw-font-semibold first-letter:tw-uppercase"
            >
              {{ data.status.name === 'other' ? 'awaiting status' : canceledMessage }}
            </Heading>
            <Subheading class="tw-line-clamp-2 tw-pt-2 tw-text-gray-600">
              {{ data.status.description }}
            </Subheading>
          </div>
        </div>
      </div>
      <div v-else class="tw-rounded-2xl tw-bg-white tw-p-8 tw-drop-shadow lg:tw-mt-8">
        <div class="tw-mb-6 tw-flex tw-items-center tw-justify-between">
          <img
            :src="logoUrl"
            :alt="branding.name"
            class="tw-max-w-52 tw-mr-3 tw-h-auto tw-max-h-9 tw-w-auto"
          />
          <div class="tw-flex tw-items-center">
            <button
              class="tw-flex tw-items-center tw-gap-2 tw-rounded-full tw-bg-gray-100 tw-px-4 tw-py-2.5 tw-font-medium tw-text-gray-800"
              @click="openTrackerDrawer"
            >
              View progress
              <ChevronRight class="tw-font-medium tw-text-gray-800" />
            </button>
          </div>
        </div>
        <div class="tw-flex tw-gap-2">
          <div class="tw-w-full">
            <Heading
              :level="5"
              class="tw-w-full tw-font-outfit !tw-text-2xl first-letter:tw-uppercase"
            >
              {{ data.status.name }}
            </Heading>
            <Subheading class="tw-line-clamp-2 tw-pt-2 tw-text-gray-600">
              {{ data.status.description }}
            </Subheading>
          </div>
        </div>

        <!-- Progress tracker on desktop and tablet -->

        <div class="tw-relative tw-mt-6 tw-flex tw-w-full tw-items-center">
          <div class="tw-absolute tw-left-0 tw-h-8 tw-w-full tw-rounded-full tw-bg-gray-200" />

          <!-- Progress bar: start -->
          <ul
            class="tw-absolute tw-left-0 tw-flex tw-w-full tw-items-center tw-justify-between tw-overflow-hidden tw-rounded-l-full tw-rounded-r-full"
          >
            <li
              v-for="(step, index) in data.statuses"
              :key="index"
              class="tw-relative tw-flex tw-items-center"
            >
              <!-- Step indicator -->
              <div class="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center">
                <div
                  v-if="index < data.status.index!"
                  class="tw-h-8 tw-w-8"
                  data-testid="before-active-step"
                />
                <div
                  v-else-if="index === data.status.index!"
                  data-testid="active-step"
                  class="tw-relative tw-h-8 tw-w-8"
                >
                  <div
                    class="tw-absolute tw-right-0 tw-h-8 tw-overflow-hidden tw-rounded-r-full tw-bg-gradient-to-l"
                    :class="linearGradient"
                    :style="{ width: `${(data.status.index! * 500)  }px` }"
                  />
                </div>
                <div v-else data-testid="after-active-step" class="tw-h-6 tw-w-6" />
              </div>
            </li>
          </ul>
          <!-- Progress bar: end -->

          <ul class="tw-relative tw-flex tw-w-full tw-items-center tw-justify-between">
            <li
              v-for="(step, index) in data.statuses"
              :key="index"
              class="tw-relative tw-flex tw-items-center"
              :class="index === data.status.index!  ? activeTrackerDot : ''"
            >
              <!-- Step indicator -->
              <div class="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full">
                <CheckmarkCircle
                  v-if="index < data.status.index!"
                  class="tw-h-8 tw-w-8 !tw-text-xl tw-text-white"
                />
                <div
                  v-else
                  class="tw-h-6 tw-w-6 tw-rounded-full tw-border-[3px]"
                  :class="trackerDots"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="$device.isMobile">
      <div
        v-if="['other', 'canceled'].includes(data.status.name)"
        class="tw--mt-8 tw-rounded-2xl tw-bg-white tw-p-8 tw-drop-shadow"
      >
        <div class="tw-mb-4 tw-flex tw-items-center">
          <img :src="logoUrl" :alt="branding.name" class="tw-h-9 tw-w-36 tw-object-contain" />
        </div>
        <div class="tw-flex tw-gap-2">
          <div>
            <Heading
              :level="5"
              class="tw-pb-4 tw-font-outfit !tw-text-2xl tw-font-semibold first-letter:tw-uppercase"
            >
              {{ data.status.name === 'other' ? 'awaiting status' : canceledMessage }}
            </Heading>
            <Subheading class="tw-line-clamp-2 tw-block sm:tw-hidden md:tw-block">
              {{ data.status.description }}
            </Subheading>
          </div>
        </div>
      </div>
      <div v-else class="tw--mt-8 tw-rounded-2xl tw-bg-white tw-p-8 tw-drop-shadow">
        <div class="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <img :src="logoUrl" :alt="branding.name" class="tw-h-9 tw-w-36 tw-object-contain" />
          <span class="tw-font-outfit tw-text-sm tw-text-gray-500">
            Step {{ data.status.index! + 1 }} of {{ data.statuses.length }}
          </span>
        </div>
        <div class="tw-flex tw-place-content-between tw-gap-2">
          <div>
            <Heading :level="5" class="tw-font-outfit !tw-text-2xl first-letter:tw-uppercase">
              {{ data.status.name }}
            </Heading>

            <!-- Progress tracker on mobile -->
            <div class="tw-relative tw-my-6 tw-flex tw-w-full tw-items-center">
              <div class="tw-absolute tw-left-0 tw-h-1 tw-w-full tw-rounded-full tw-bg-gray-200" />
              <div
                class="tw-absolute tw-left-0 tw-h-1 tw-rounded-full"
                :class="mobileTrackerLine"
                :style="{ width: `${(data.status.index! / (data.statuses.length - 1)) * 100 }%` }"
              />

              <ul class="tw-relative tw-flex tw-w-full tw-items-center tw-justify-between">
                <li
                  v-for="(step, index) in data.statuses"
                  :key="index"
                  class="tw-relative tw-flex tw-items-center"
                >
                  <div
                    class="tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full"
                  >
                    <li
                      v-if="index < data.status.index!"
                      class="tw-rounded-full tw-p-2"
                      :class="mobileTrackerLine"
                    />
                    <li
                      v-else-if="index === data.status.index!"
                      class="tw-h-5 tw-w-5 tw-rounded-full tw-border-[3px] tw-p-2.5"
                      :class="trackerDots"
                    />
                    <div v-else class="tw-h-4 tw-w-4 tw-rounded-full tw-bg-gray-200" />
                  </div>
                </li>
              </ul>
            </div>

            <Subheading class="tw-line-clamp-2 tw-max-w-[700px] tw-text-gray-600">
              {{ data.status.description }}
            </Subheading>
          </div>
        </div>
        <div class="tw-mt-4">
          <button
            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-full tw-bg-gray-100 tw-px-4 tw-py-2.5 tw-font-medium tw-text-gray-800"
            @click="openTrackerDrawer"
          >
            View progress
            <ChevronRight class="tw-font-medium tw-text-gray-800" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <V2TrackerDrawer v-model:visible="showDrawer" />
</template>

<script setup lang="ts">
import { Branding, MatterType } from '@torticity/types'

import CheckmarkCircle from '~icons/fluent/checkmark-circle-16-filled'
import ChevronRight from '~icons/fluent/chevron-right-16-filled'

const props = defineProps<{
  branding: Branding
}>()

const logoUrl = computed(() => {
  return props.branding.svgLogoUrl || props.branding.pngLogoUrl
})

const { getThemeToken } = useTheme()
const linearGradient = getThemeToken('linear-gradient')
const trackerDots = getThemeToken('tracker-dots')
const activeTrackerDot = getThemeToken('active-tracker-dot')
const mobileTrackerLine = getThemeToken('mobile-tracker-line')

const { isSuccess, data } = useClaimStatusQuery()
const { $auth } = useNuxtApp()

const showDrawer = ref(false)

const analytics = useAnalytics()
const canceledMessage = computed(() => {
  return $auth.user?.lead?.matterType === MatterType.AUTO_ACCIDENT
    ? 'this claim has been canceled'
    : 'this claim has been suspended'
})

function openTrackerDrawer() {
  analytics.track('ElementViewed', {
    elementType: 'claim tracker',
    text: data?.value?.status.name,
  })
  analytics.trackElementClicked({ elementType: 'button', text: 'View' })
  showDrawer.value = true
}
</script>
