<template>
  <PrimeDrawer
    position="right"
    :modal="false"
    :dismissable="false"
    :show-close-icon="false"
    class="tw-mt-32 tw-w-full tw-flex-1 tw-rounded-2xl tw-drop-shadow-2xl sm:tw-mt-0 sm:!tw-max-w-[400px] sm:tw-rounded-none"
    :pt="{
      content: {
        class: 'tw-p-6',
      },
      header: {
        class: 'tw-px-6 tw-py-4 tw-border-b tw-border-gray-200',
      },
      mask: {
        class: 'tw-bg-black/50',
      },
    }"
  >
    <template #header>
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
        <h2 class="tw-font-outfit tw-text-2xl tw-font-medium tw-text-gray-900">
          Your case progress
        </h2>
        <button
          class="tw-rounded-full tw-p-2 tw-text-gray-600 hover:tw-bg-gray-100 hover:tw-text-gray-900"
          @click="closeDrawer"
        >
          <DismissIconLarge class="tw-h-6 tw-w-6" />
        </button>
      </div>
    </template>
    <V2Tracker />
  </PrimeDrawer>
</template>

<script setup lang="ts">
import DismissIconLarge from '~icons/fluent/dismiss-24-regular'

const emit = defineEmits(['update:visible'])

function closeDrawer() {
  emit('update:visible', false)
}
</script>
