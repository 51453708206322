<template>
  <div class="tw-flex tw-min-h-screen tw-flex-col tw-bg-gray-50">
    <div
      class="tw-rounded-b-[30px] tw-bg-gradient-to-b"
      :class="[linearGradient, cobrandedExperience ? 'xs:tw-h-64 lg:tw-h-60' : 'xs:tw-h-64']"
    >
      <V2HeaderNav :cobranded-experience="cobrandedExperience" />
      <div
        class="tw-hidden tw-h-0.5 tw-w-full tw-border-b tw-border-white tw-border-opacity-20 lg:tw-block"
      />
    </div>
    <div class="tw-relative tw-flex tw-flex-1 tw-items-start tw-justify-center">
      <section
        v-show="!isLoading"
        class="tw--mt-44 tw-w-full tw-rounded-lg tw-px-6 lg:tw-w-3/4 lg:tw-p-0"
      >
        <V2DashboardTracker :branding="branding" />
        <div class="tw-min-h-full md:tw-flex">
          <main class="tw-relative tw-flex tw-flex-1 tw-flex-col">
            <slot />
          </main>
        </div>
      </section>
    </div>

    <!-- Desktop footer -->
    <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
      <V2AuthenticatedFooter class="tw-hidden lg:tw-block lg:tw-w-3/4" />
    </div>

    <!-- Tablet and mobile navigation -->
    <div
      class="tw-fixed tw-bottom-0 tw-h-[72px] tw-w-full tw-border-t-2 tw-border-gray-200 tw-bg-white lg:tw-hidden"
    >
      <V2Navigation />
    </div>

    <AppToast />
  </div>
</template>

<script setup lang="ts">
const query1 = useClaimStatusQuery()
const query2 = useActionRequestsQuery()

const branding = useBrand()
const cobrandedExperience = computed(() => branding.value.brandingExperience === 'cobrand')

const isLoading = computed(() => !query1.isFetched.value && !query2.isFetched.value)
const { getThemeTokenString } = useTheme()
const linearGradient = getThemeTokenString('linear-gradient')

// Use the composable for dynamic head
const { applyHead } = useDynamicHead()
applyHead()

onMounted(() => {
  localStorage.removeItem('flow')
})
</script>
